export default defineNuxtRouteMiddleware(async ({ name, params }) => {
  const localePath = useLocalePath()
  const locales = ['en', 'es', 'fr']
  const locale = name.substr(-2)

  if (!locales.includes(locale)) {
    return navigateTo(
      localePath('software-comparison'),
      {
        redirectCode: 301,
      },
    )
  }

  const { data } = await useFetch(`/api/content/pages/software-comparisons/${locale}`, {
    key: `middleware-software-comparison-[slug]-${params.slug}-${locale}`,
    transform: (input) => {
      const { allModelComparisons } = input
      const page = allModelComparisons.find(({ slug }) => slug === params.slug)
      return {
        page,
      }
    },
  })

  if (!data.value.page) {
    return navigateTo(
      localePath('software-comparison'),
      {
        redirectCode: 301,
      },
    )
  }
})
